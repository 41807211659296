import { mdiCalendarMonth } from "@mdi/js";
import { SvgIcon } from "@material-ui/core";
import { ColorDynamic } from "@superdispatch/ui";

export interface CalendarMonthProps {
  color?: ColorDynamic;
}

export function CalendarMonth({
  color = ColorDynamic.Dark500,
}: CalendarMonthProps) {
  return (
    <SvgIcon>
      <path fill={color} d={mdiCalendarMonth} />
    </SvgIcon>
  );
}
