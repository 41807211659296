import { ArrowBack } from "@material-ui/icons";
import {
  AppBar,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  IconButton,
  styled as MuiStyled,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { ColorDynamic } from "@superdispatch/ui";
import styled from "styled-components";
import { ReactNode } from "react";

const StyledContainer = styled.div`
  background-color: ${ColorDynamic.White};
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Wrapper = MuiStyled(DialogContent)(({ theme }) => ({
  boxSizing: "border-box",
  height: "100%",
  maxWidth: "640px",
  [`${theme.breakpoints.up("xs")}`]: {
    padding: 8,
  },

  [`${theme.breakpoints.up("sm")}`]: {
    padding: 12,
  },
}));

interface TermsAndConditionsProps {
  open: boolean;
  children: ReactNode;
  onClose: () => void;
}
export function TermsAndConditionsDialog({
  open,
  children,
  onClose,
}: TermsAndConditionsProps) {
  return (
    <Dialog open={open} fullScreen={true} maxWidth="xs">
      <AppBar position="sticky">
        <Toolbar>
          <IconButton edge="start" onClick={onClose} size="medium">
            <ArrowBack htmlColor={ColorDynamic.Dark300} />
          </IconButton>

          <Typography variant="h2">Terms and Conditions</Typography>
        </Toolbar>
      </AppBar>
      <StyledContainer>
        <Wrapper>
          <Card>
            <CardContent>
              <Typography align="inherit" display="block" variant="body2">
                {children}
              </Typography>
            </CardContent>
          </Card>
        </Wrapper>
      </StyledContainer>
    </Dialog>
  );
}
