import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { Button, ColorDynamic } from "@superdispatch/ui";
import { useHistory } from "react-router";
import { useResponsive } from "shared/utils/useResponsive";
import styled from "styled-components";

import { SignatureConfirmedIcon } from "./SignatureCorfirmedIcon";

const StyledCard = styled(Card)`
  width: 640px;
  max-width: 100%;

  & > .MuiCardContent-root {
    padding: 32px 28px;
  }
`;

const StyledIcon = styled(SignatureConfirmedIcon)`
  font-size: 128px;
  fill: ${ColorDynamic.White};
`;

export function SignatureConfirmedPage({
  BOLlink,
  loadId,
}: {
  BOLlink: string;
  loadId: string;
}) {
  const r = useResponsive();
  const history = useHistory();

  return (
    <StyledCard>
      <CardContent>
        <Grid
          container={true}
          alignItems="center"
          direction="column"
          spacing={2}
        >
          <Grid item={true}>
            <StyledIcon />
          </Grid>

          <Grid item={true}>
            <Typography align="center" variant={r({ xs: "h4", sm: "h3" })}>
              Pickup signature is confirmed {loadId && `for Load ID: ${loadId}`}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography align="center" color="textSecondary">
              Your signature and phone number will be displayed on the Bill of
              Lading.
            </Typography>
          </Grid>

          <Grid item={true} />
          <Grid item={true}>
            <Button
              variant="text"
              onClick={() => {
                history.replace(BOLlink);
              }}
            >
              View Vehicle Inspection
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
}
