import { Check, NearMe } from "@material-ui/icons";
import { Link, Typography } from "@material-ui/core";
import { FormattedDate } from "@superdispatch/dates";
import {
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
} from "@superdispatch/ui";
import { Box, TextBox } from "@superdispatch/ui-lab";
import { truncate } from "lodash";
import { ReactNode, useMemo, useState } from "react";
import { VenueDTO } from "shared/dto/VenueDTO";
import { isDateWithin } from "shared/utils/dates";
import { formatCSTDate } from "shared/utils/formatDate";
import styled from "styled-components";

import { FormattedRelativeDate } from "../core/RelativeDates";
import { LoadDriverLocationDTO } from "../data/ETADTO";
import { CalendarMonth } from "../icons/CalendarMonth";
import { DriverLocation } from "./DriverLocation";
import { ETATitle } from "./ETATitle";

export const IconContainer = styled.div<{ color?: ColorDynamic }>`
  & > * {
    font-size: 16px;
    height: 28px;
  }
`;

export interface ETADetailsProps {
  venue: VenueDTO;
  driverLocation: LoadDriverLocationDTO | undefined;
}

export function ETADetails({ venue, driverLocation }: ETADetailsProps) {
  const driver = driverLocation?.driver;

  const isWithoutPickupDates =
    venue.pickup.actual_date === null && venue.pickup.scheduled_date === null;

  const isWithoutDeliveryDates =
    venue.delivery.actual_date === null &&
    venue.delivery.scheduled_date === null;

  const isPickupETAAvailable =
    venue.eta.estimated_date.from !== null &&
    venue.eta.estimated_date.until !== null;

  const isDeliveryETAAvailable =
    venue.delivery_eta?.estimated_date.from !== null &&
    venue.delivery_eta?.estimated_date.until !== null;

  const isDriveLocationActual = useMemo(
    () => isDateWithin(driver?.location.time, { days: 3 }),
    [driver],
  );

  return (
    <>
      {venue.status === "New" &&
        (isWithoutPickupDates ? (
          <ETADetailsContainer
            icon={<CalendarMonth />}
            title="Pickup dates are not available yet"
            subtitle="Contact your transporter to clarify the dates"
          />
        ) : isPickupETAAvailable ? (
          <ETADetailsContainer
            icon={<NearMe color="primary" />}
            title={<ETATitle type="pickup" eta={venue.eta.estimated_date} />}
            subtitle={
              <>
                Carrier’s estimation · updated{" "}
                <FormattedRelativeDate date={venue.eta.updated_at} />
              </>
            }
            notes={venue.eta.notes ? venue.eta.notes : undefined}
          />
        ) : (
          <ETADetailsContainer
            icon={<CalendarMonth />}
            title={
              <>
                Pickup scheduled for{" "}
                <FormattedDate
                  date={venue.pickup.scheduled_date}
                  variant="ShortDate"
                />
              </>
            }
          />
        ))}
      {venue.status === "Picked up" &&
        (isWithoutDeliveryDates ? (
          <ETADetailsContainer
            icon={<CalendarMonth />}
            title="Delivery dates are not available yet"
            subtitle="Contact your transporter to clarify the dates"
          />
        ) : isDeliveryETAAvailable && venue.delivery_eta ? (
          <ETADetailsContainer
            icon={<NearMe color="primary" />}
            title={
              <ETATitle
                type="delivery"
                eta={venue.delivery_eta.estimated_date}
              />
            }
            subtitle={
              driver && isDriveLocationActual ? (
                <DriverLocation driver={driver} />
              ) : undefined
            }
            notes={
              venue.delivery_eta.notes ? venue.delivery_eta.notes : undefined
            }
          />
        ) : (
          <ETADetailsContainer
            icon={<CalendarMonth />}
            title={
              <>
                Scheduled delivery for{" "}
                <FormattedDate
                  date={venue.delivery.scheduled_date}
                  variant="ShortDate"
                />
              </>
            }
            subtitle={
              driver && isDriveLocationActual ? (
                <DriverLocation driver={driver} />
              ) : undefined
            }
          />
        ))}
      {venue.status === "Delivered" && (
        <ETADetailsContainer
          icon={<Check color="primary" />}
          title={
            <>
              Delivered on{" "}
              <FormattedDate
                date={venue.delivery.actual_date}
                variant="ShortDate"
              />
            </>
          }
          subtitle={
            venue.delivery.actual_date &&
            formatCSTDate(venue.delivery.actual_date)
          }
        />
      )}
    </>
  );
}

interface ETADetailsContainerProps {
  icon: ReactNode;
  title: ReactNode;
  subtitle?: ReactNode;
  notes?: string;
}

export function ETADetailsContainer({
  icon,
  title,
  subtitle,
  notes,
}: ETADetailsContainerProps) {
  const [isFullNote, setFullNote] = useState(false);
  const notesTruncated = useMemo(
    () =>
      truncate(notes, {
        length: 175,
        omission: "…",
      }),
    [notes],
  );

  return (
    <Inline>
      <Stack space="xxsmall">
        <Columns align="top" space="xsmall">
          <Column width="content">
            <IconContainer>{icon}</IconContainer>
          </Column>
          <Column width="adaptive">
            <Typography aria-label="ETA details title" variant="h3">
              {title}
            </Typography>
          </Column>
        </Columns>
        {subtitle && (
          <Columns space="xsmall">
            <Column width="content">
              <Box width="16px" display="flex" />
            </Column>
            <Column width="adaptive">
              <TextBox
                aria-label="ETA details subtitle"
                variant="caption"
                color="secondary"
              >
                {subtitle}
              </TextBox>
            </Column>
          </Columns>
        )}
        {notesTruncated && (
          <Columns space="xsmall">
            <Column width="content">
              <Box width="16px" display="flex" />
            </Column>
            <Column width="adaptive">
              <Box
                aria-label="ETA notes"
                backgroundColor="Blue50"
                padding="xsmall"
                borderRadius="small"
              >
                <Typography>
                  {isFullNote ? notes : notesTruncated}{" "}
                  {notes && notesTruncated.length < notes.length && (
                    <Link
                      onClick={() => {
                        setFullNote(!isFullNote);
                      }}
                      color="primary"
                      component="button"
                    >
                      {isFullNote ? "Read less" : "Read more"}
                    </Link>
                  )}
                </Typography>
              </Box>
            </Column>
          </Columns>
        )}
      </Stack>
    </Inline>
  );
}
