import { LatLngTuple } from "leaflet";
import { useQuery } from "react-query";

export interface OSMGeocodeResponse {
  place_id: number;
  licence: string;
  osm_type: string;
  osm_id: number;
  lat: string;
  lon: string;
}

export function useOSMGeocode(address: string) {
  return useQuery(
    ["osm-geocode", address],
    () =>
      fetch(
        `https://nominatim.openstreetmap.org/search?q=${address}&format=json&limit=1`,
      ).then((res) => res.json() as Promise<OSMGeocodeResponse[]>),
    {
      enabled: !!address,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    },
  );
}

export function getPosition(geocode: OSMGeocodeResponse): LatLngTuple | null {
  return geocode?.lat && geocode?.lon
    ? [Number(geocode.lat), Number(geocode.lon)]
    : null;
}
