import { ColorDynamic } from "@superdispatch/ui";
import styled from "styled-components";

export const Timeline = styled.ul`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 0;
  padding: 0;
`;

export const TimelineSeparator = styled.div`
  flex: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

type TimelineDotColor = "default" | "inherit" | "primary" | "secondary";

export const TimelineDot = styled.div<{ color?: TimelineDotColor }>`
  display: flex;
  padding: 2px;
  align-self: baseline;
  margin-top: 6px;
  margin-bottom: 8px;
  border-radius: 50%;
  border: 2px solid transparent;

  background-color: ${({ color }) =>
    color === "inherit"
      ? "inherit"
      : color === "primary"
      ? ColorDynamic.Blue300
      : color === "secondary"
      ? ColorDynamic.Red300
      : ColorDynamic.Silver400};
`;

export const TimelineConnector = styled.div<{ color?: TimelineDotColor }>`
  width: 1px;
  flex-grow: 1;

  background-color: ${({ color }) =>
    color === "inherit"
      ? "inherit"
      : color === "primary"
      ? ColorDynamic.Blue300
      : color === "secondary"
      ? ColorDynamic.Red300
      : ColorDynamic.Silver400};
`;

export const TimelineContent = styled.div`
  width: 100%;
  flex-shrink: 1;
  flex-grow: 0;
  padding: 0 16px;
`;

export const TimelineItem = styled.li`
  display: flex;
  position: relative;
  list-style: none;
  min-height: 36px;
`;
