import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import { Icon, LatLngBounds, LatLngTuple } from "leaflet";
import "leaflet/dist/leaflet.css";
import styled from "styled-components";
import { DriverLocationDTO } from "../data/ETADTO";
import { SingleDriverLocationDTO } from "../data/ETADTO";
import { useEffect, useMemo, useState } from "react";
import PickupIcon from "../assets/pickup-pin.svg";
import DeliveryIcon from "../assets/delivery-pin.svg";
import DriverIcon from "../assets/location-pin.svg";
import Control from "react-leaflet-custom-control";
import { ButtonBase, Dialog, DialogContent } from "@material-ui/core";
import { Fullscreen, FullscreenExit } from "@material-ui/icons";
import { getPosition, useOSMGeocode } from "shared/helpers/osm";
import { useResponsiveValue } from "@superdispatch/ui";

const Container = styled(MapContainer)`
  width: 100%;
  aspect-ratio: 16/9;

  .leaflet-tile-pane {
    filter: grayscale(1) !important;
  }
`;
const StyledButtonBase = styled(ButtonBase)`
  background: white;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  padding: 4px;
  border-radius: 4px;
  :hover {
    background: #f0f0f0;
  }
`;

const DEFAULT_CENTER: [number, number] = [39.099724, -94.578331];
const DEFAULT_ZOOM = 4;

interface Props {
  pickupAddress: string;
  deliveryAddress: string;
  driverLocation:
    | DriverLocationDTO
    | SingleDriverLocationDTO
    | undefined
    | null;
}

function FitBounds({
  positions,
}: {
  positions: Array<LatLngTuple | null | undefined>;
}) {
  const map = useMap();

  const bounds = useMemo(
    () => new LatLngBounds(positions.filter(Boolean) as LatLngTuple[]),
    [positions],
  );

  useEffect(() => {
    if (bounds.isValid()) {
      map.fitBounds(bounds.pad(0.1));
    }
  }, [bounds, map]);

  return null;
}

export function Map({ pickupAddress, deliveryAddress, driverLocation }: Props) {
  const { data: pickupGeocode } = useOSMGeocode(pickupAddress);
  const { data: deliveryGeocode } = useOSMGeocode(deliveryAddress);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const isMobile = useResponsiveValue(true, false);
  const pickupPosition = pickupGeocode?.[0]
    ? getPosition(pickupGeocode[0])
    : null;
  const deliveryPosition = deliveryGeocode?.[0]
    ? getPosition(deliveryGeocode[0])
    : null;

  const driverPosition =
    driverLocation && driverLocation.location
      ? ([
          driverLocation.location.latitude,
          driverLocation.location.longitude,
        ] as LatLngTuple)
      : null;

  const renderMapContent = (
    <Container
      center={DEFAULT_CENTER}
      zoom={DEFAULT_ZOOM}
      attributionControl={false}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Control prepend={true} position="topleft">
        <StyledButtonBase
          color="inherit"
          title="Toggle Fullscreen"
          onClick={() => {
            setIsFullscreen(!isFullscreen);
          }}
        >
          {!isFullscreen ? <Fullscreen /> : <FullscreenExit />}
        </StyledButtonBase>
      </Control>
      {pickupPosition && (
        <Marker
          position={pickupPosition}
          icon={
            new Icon({
              iconUrl: PickupIcon,
              iconSize: [24, 24],
            })
          }
        />
      )}
      {driverPosition && (
        <Marker
          position={driverPosition}
          icon={
            new Icon({
              iconUrl: DriverIcon,
              iconSize: [32, 32],
            })
          }
        />
      )}
      {deliveryPosition && (
        <Marker
          position={deliveryPosition}
          icon={
            new Icon({
              iconUrl: DeliveryIcon,
              iconSize: [24, 24],
            })
          }
        />
      )}
      <FitBounds
        positions={[pickupPosition, deliveryPosition, driverPosition]}
      />
    </Container>
  );

  return (
    <div>
      {renderMapContent}
      <Dialog
        open={isFullscreen}
        onClose={() => {
          setIsFullscreen(false);
        }}
        maxWidth="lg"
        fullWidth={true}
        fullScreen={isMobile}
      >
        <DialogContent>{renderMapContent}</DialogContent>
      </Dialog>
    </div>
  );
}
