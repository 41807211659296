import { ColorDynamic, Stack, ThemeProvider } from "@superdispatch/ui";
import { Component, ReactNode } from "react";

import { Box, Button, TextBox } from "@superdispatch/ui-lab";
import { Card, CardContent } from "@material-ui/core";
import { WarningRounded } from "@material-ui/icons";
import { logError } from "../utils/monitoring";

function ErrorScreen() {
  return (
    <ThemeProvider>
      <Box
        maxWidth="800px"
        marginLeft="auto"
        marginRight="auto"
        marginTop={["small", "xxlarge"]}
      >
        <Card>
          <CardContent>
            <Box padding="xxlarge">
              <Stack align="center" space="medium">
                <Stack align="center" space="small">
                  <WarningRounded
                    htmlColor={ColorDynamic.Yellow500}
                    style={{ fontSize: "54px" }}
                  />

                  <Stack align="center">
                    <TextBox variant="heading-2">Something Went Wrong</TextBox>
                  </Stack>
                </Stack>

                <Button
                  size="large"
                  variant="primary"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Reload Page
                </Button>
              </Stack>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </ThemeProvider>
  );
}

export interface ErrorBoundaryProps {
  children: ReactNode;
}

export interface ErrorBoundaryState {
  hasError?: boolean;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    logError(error, "ErrorBoundary");
  }

  render() {
    if (this.state.hasError) {
      return <ErrorScreen />;
    }

    return this.props.children;
  }
}
